import React, { Fragment } from 'react';
import PageTitle from '../components/pagetitle'
import Error from '../components/404'
import Footer from '../components/footer'
import Scrollbar from '../components/scrollbar'

const NotFoundPage = () => {
    return (
        <Fragment>
            <PageTitle pageTitle={'404'} pagesub={'404'} />
            <Error />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default NotFoundPage;

