import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

const Error = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="error-404-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="content clearfix">
                            <div className="error-message">
                                <h3>Oops! Página Não Encontrada!</h3>
                                <p>Desculpe mas não encontramos a página que você tentou acessar, clique no botão abaixo para voltar a nossa Home.</p>
                                <Link onClick={ClickHandler} href="/" className="theme-btn"> Voltar para home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Error;